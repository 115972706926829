html {
    overflow-y: auto; // main page has no scrollbar, visualy better to not show an grayed out bar because users might think your are not able to use scroll
}

@include mq($from: lg) {
    html {
        font-size: 125%; // 20px
    }
}

@include mq($from: xxl) {
    html {
        font-size: 137.5%; // 22px
    }
}
